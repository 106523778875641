globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"dd6e34666d22bb52515dd6f664c1f0cdba885503"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/app";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import type { SamplingContext } from '@sentry/types';
import {
  standardSentryConfig,
  extraClientConfig,
  errorMiddleware,
} from '@mentimeter/sentry-config';
import { dashboardErrorMiddleware } from './sentry-error-middleware';

const environmentName = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ?? 'dev';
const shouldHavePerformanceMonitor = environmentName === 'prod';

Sentry.init({
  dsn: 'https://2a6285d329947beb0b3109b0f528b251@o866780.ingest.sentry.io/4506575158706176',
  ...standardSentryConfig,
  ...extraClientConfig,
  beforeSend: errorMiddleware(dashboardErrorMiddleware),
  tracesSampler: (samplingContext: SamplingContext) => {
    if (!shouldHavePerformanceMonitor) {
      return 0;
    }
    const TRACES_PER_PATH: { [key: string]: number } = {
      '/app/home': 0.05,
    };

    const sampleRate =
      TRACES_PER_PATH[samplingContext.location?.pathname ?? ''];

    return sampleRate ?? 0;
  },
});
